import React from "react";
import tw from "twin.macro";
import Img from "gatsby-image";
import { useTranslation } from "react-i18next";

import CustomLink from "@components/link";
import ButtonPrimary from "@components/buttons/primary";

const Wrapper = tw.div`
  hidden mb-40 w-1/2 px-0 pr-40 
  md:block 
  lg:w-1/3 lg:pr-30
`;

const Box = tw.div`block relative h-full w-full overflow-hidden`;

const Shadow = tw.div`block absolute top-0 left-0 h-full w-full bg-shadow z-10`;

const Image = tw(Img)`block absolute h-full z-0`;

const Content = tw.div`
  block absolute bottom-30 left-0 z-20 px-30 
  lg:bottom-60
`;

const Intro = tw.p`text-sm text-white`;

const Title = tw(CustomLink)`
  block mt-20 mb-30 text-2xl text-white leading-30 -tracking-016 
  lg:text-3xl
`;

const Case = ({ data }: { data: any }) => {
  const { t } = useTranslation();
  const url = `/portfolio/${data.slug.current}`;

  return (
    <Wrapper>
      <Box>
        <Shadow/>
        <Image fluid={data.portfolioImage.asset.fluid} />
        <Content>
          <Intro>
            {t(`Blog:Case`)}
          </Intro>
          <Title to={url} type='noIntl'>
            {data.title}
          </Title>
          <CustomLink to={url} type='noIntl'>
            <ButtonPrimary text={'Zobacz projekt'} />
          </CustomLink>
        </Content>
      </Box>
    </Wrapper>
  )
};

export default Case;
