import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import CustomLink from "@components/link";

const Wrapper = tw.div`flex justify-center`;

const Item = styled(CustomLink)<{ isActive: boolean }>`
  ${tw`flex justify-center items-center w-44 h-44 text-grey rounded-full transition-colors duration-300`}
  ${({ isActive }) => isActive && tw`bg-orange text-white pointer-events-none`}

  &:hover { 
    ${tw`text-orange`} 
  }
`;

const Pagination = ({ slug, pages, page }: { slug: String, pages: number, page: number }) => {
  const pagesArr = [...Array(pages).keys()];
  const isPagination = pagesArr.length > 1;

  return isPagination && (
    <Wrapper>
      {pagesArr.map(i => (
        <Item
          key={i}
          isActive={i + 1 === page}
          to={i ? `${slug}/page/${i + 1}` : slug}
          type='noIntl'
        >
          {i + 1}
        </Item>
      ))}
    </Wrapper>
  );
};

export default Pagination;
