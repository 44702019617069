import React from 'react';
import { useViewportScroll } from 'framer-motion';
import { useIntl } from 'gatsby-plugin-intl';

import useWindowSize from '@utils/useWindowSize';

import Layout from '@layouts/index';
import SEO from '@components/seo';
import Cta from '@components/cta';

import Header from '@components/blog/header';
import Collections from '@components/blog/collections';
import Posts from '@components/blog/posts';
import Pagination from '@components/blog/pagination';

const BlogPage = ({ pageContext }: any) => {
  const intl = useIntl();
  const { width } = useWindowSize();
  const { scrollY } = useViewportScroll();
  const { type, slug, title, bio, limit, skip, postsPages, currentPage } = pageContext;

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'SEO.Title.Blog' })}
        description={intl.formatMessage({ id: 'SEO.Description.Blog' })}
      />
      <Header width={width} title={title} bio={bio} />
      <Collections width={width} type={type} active={title === 'Blog' ? 'Wszystko' : title} categories={[]}/>
      <Posts width={width} type={type} collection={title} limit={limit} skip={skip} page={currentPage}/>
      <Pagination slug={slug} pages={postsPages} page={currentPage}/>
      <Cta width={width} scroll={scrollY} />
    </Layout>
  );
};

export default BlogPage;
