import React, { Fragment } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import { motion } from "framer-motion";

import Item from "@components/blog/item";
import Case from "@components/blog/case";

type PostsTypes = {
  width: Number,
  type: String,
  collection: String,
  limit: number, 
  skip: number, 
  page: number
};

const Wrapper = tw(motion.div)`
  relative py-40
  sm:px-0 sm:flex sm:flex-wrap
  lg:opacity-0
`;

const Text = tw.p`w-full text-center`;

const Link = styled.a`
  ${tw`mx-auto text-orange transition-colors duration-300`}

  &:hover {
    ${tw`text-orangeHover`}
  }
`;

const EmptyComponent = ({ linked }: { linked: boolean }) => {
  const { t } = useTranslation();

  if (linked) return (
    <Link href={t(`Blog:Marta`)} target='_blank' rel='noopener'>
      {t(`Blog:MartaText`)}
    </Link>
  )

  return <Text>{t(`Blog:Empty`)}</Text>
};

const Posts = ({ width, type, collection, limit, skip, page }: PostsTypes) => {
  const { allSanityPost, allSanityCases } = useStaticQuery(graphql`
    query {
      allSanityPost(sort: { fields: publishedAt, order: DESC }) {
        nodes {
          publishedAt
          previewImage { asset { fluid { ...GatsbySanityImageFluid }}}
          slug { pl { current }}
          author { name }
          title { _rawPl }
          preview { _rawPl }
          categories { title { pl }}
        }
      }
      allSanityCases(sort: { fields: order, order: ASC }) {
        nodes {
          order
          title
          slug { current }
          portfolioImage { asset { fluid { ...GatsbySanityImageFluid }}}
        }
      }
    }
  `);
  const cases = allSanityCases.nodes;
  const isMainPage = collection === 'Blog';
  const isAuthorPage = type === 'author'
  const pagesData = {
    author: allSanityPost.nodes.filter(post => post.author.name === collection),
    blog: allSanityPost.nodes,
    collection: allSanityPost.nodes.filter(post => post.categories.some(e => e.title.pl === collection))
  };
  const data = pagesData[type];
  const pagePosts = data?.slice(skip, skip + limit);
  const portfolioCase = cases[page - 1] ? cases[page - 1] : cases[0];
  const isMartaPage = isAuthorPage && collection === 'Marta Sulikowska';

  return (
    <Wrapper 
      animate={ width >= 1024 && { opacity: 1 }}
      transition={{ duration: .6, delay: 1.7, ease: [0.13, -0.22, 1, 1.02] }}
    >
      { pagePosts.length
        ? (pagePosts.map((post, index) =>
            <Fragment key={index}>
              <Item data={post}/>
              {(isMainPage && index === 1) && <Case data={portfolioCase}/>}
            </Fragment>
          )
        ) : <EmptyComponent linked={isMartaPage} />
      }
    </Wrapper>
  );
};

export default Posts;
