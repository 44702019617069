import React from "react";
import { useTranslation } from "react-i18next";
import { up, down } from "styled-breakpoints";
import styled from "styled-components";
import { motion } from "framer-motion";
import tw from "twin.macro";

import ArrowRed from "@components/arrows/red"

const Wrapper = tw.div`
  relative flex flex-wrap ml-40 mr-20 mb-35
  sm:ml-20 sm:mb-50
  md:ml-0
  lg:mt-175 lg:mb-50
`;

const Header = styled(motion.div)`
  ${tw`
    w-full mb-10 text-xl leading-33 -tracking-016
    md:mt-140 md:mb-60 md:text-2xl
    lg:order-2 lg:relative lg:top-120 lg:my-0 lg:text-8xl lg:leading-77 lg:-tracking-028 lg:opacity-0
  `}
  
  ${down('md')} {
    transform: none !important;
  }

  ${up('lg')} {
    width: 45%;
  }
`;

const Character = styled(motion.div)<{ hidden: boolean }>`
  ${tw`
    font-pangramBold w-30 text-sm text-second leading-32 -tracking-021
    lg:order-1 lg:w-1/12 lg:mt-15 lg:text-lg lg:opacity-0 lg:leading-32 lg:-tracking-03`
  }
  ${({ hidden }) => hidden && tw`hidden`};
`;

const Description = tw(motion.div)`
  w-4/5 text-second -tracking-024
  md:w-150 md:text-sm
  lg:order-3 lg:w-5/12 lg:ml-auto lg:opacity-0 lg:text-2xl lg:leading-60
`;

const Bio = tw(motion.div)`
  pr-30 text-sm text-second -tracking-024 
  md:pr-190 md:text-base
  lg:order-3 lg:w-1000 lg:mt-30 lg:opacity-0 lg:text-md lg:leading-32
`;

const ArrowCircle = styled.div<{ moved: boolean }>`
  ${tw`absolute! hidden md:block md:top-50 lg:-top-160`}
  ${({ moved }) => moved ? tw`md:-right-120 lg:-right-50` : tw`md:right-50 lg:-right-240`};
`;

const BlogHeader = ({ width, title, bio }: { width: Number, title: String, bio: Object }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header
        initial={ width >= 1024 && 'hidden'}
        animate={ width >= 1024 && 'visible' }
        transition={{
          y: { duration: .917, delay: .4, ease: [.88, .14, .12, .86] },
          opacity: { duration: .458, delay: .65, ease: 'linear' },
          rotate: { duration: .917, delay: .4, ease: [.88, .14, .12, .86] }
        }}
        variants={{
          'visible': { y: -120, opacity: 1, rotate: 0 },
          'hidden': { rotate: -8 }
        }}
      >
        {title}
      </Header>
      <Character
        animate={ width >= 1024 && { opacity: 1 }}
        transition={{ duration: .6, delay: .8, ease: [.88, .14, .12, .86] }}
        hidden={bio}
      >
        {t('Blog:Character')}
      </Character>
      { bio ? (
          <Bio
            animate={ width >= 1024 && { opacity: 1 }}
            transition={{ duration: .6, delay: 1, ease: [.88, .14, .12, .86] }}
          >
            { bio.pl }
          </Bio> 
        ) : (
          <Description
          animate={ width >= 1024 && { opacity: 1 }}
          transition={{ duration: .6, delay: 1, ease: [.88, .14, .12, .86] }}
          > 
            {t('Blog:Description1')}
            <br/>
            {t('Blog:Description2')}
          </Description>
        )
      }
      <ArrowCircle moved={bio}>
        <ArrowRed />
      </ArrowCircle>
    </Wrapper>
  );
};

export default BlogHeader;
